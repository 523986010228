<nav class="flex items-center flex-wrap white p-6 justify-between">
  <div class="flex items-center flex-no-shrink text-white mr-6">
    <img
      class="mx-auto h-12 w-auto"
      src="https://speisewerkstatt-uebigau.de/wp-content/uploads/2020/12/cropped-cropped-Logo_Speisewerkstatt.jpg"
      alt="Workflow"
    />
  </div>
  <div
    *ngIf="currentUser"
    class=" block flex-grow lg:flex lg:items-center lg:w-auto lg:justify-end"
  >
    <div class=" flex justify-end">
      <button
        (click)="logOut()"
        class="inline-block text-sm px-4 py-2 leading-none border rounded text-red-600 border-red-600 hover:border-transparent hover:text-white hover:bg-red-600 mt-4 lg:mt-0"
      >
        LogOut
      </button>
    </div>
  </div>
</nav>
