import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser: any;
  constructor(private router: Router, private toastService: ToastrService,) {
    router.events.subscribe((val) => {
      this.currentUser = localStorage.getItem('currentUser');
    });
  }

  ngOnInit(): void {}

  logOut() {
    localStorage.clear();
    this.toastService.success('abgemeldet');
    this.router.navigate(['/']);
  }
}
